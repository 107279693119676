<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="import_history"
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :selectedSource="selectedSource"
                          title="common.menu.system-tools.import-history"
                >
                    <template #cell(file)="row">
                        <a href="#" @click.prevent="downloadFile(row.item.file)">{{ row.item.file }}</a>
                    </template>
                    <template #cell(actions)="row">
                        <b-button
                            @click="$root.$children[0].openModal('import-history-log-modal', {id:row.item.id})"
                            variant="primary"
                            size="sm"
                        >
                            {{ $t('common.button.open') }}
                        </b-button>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'ImportHistory',
    mixins: [commonTable],
    data() {
        return {
            saveFilters: false,
        }
    },
    methods: {
        ...mapGetters('Import', ['getHistoryTable']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Import/fetchHistoryTable', 'getHistoryTable');
        },
        getActions() {
        },
        downloadFile(file) {
            this.$root.downloadDocument(file, 'import_files')
        },
    },
}
</script>